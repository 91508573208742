<template>
  <div>
    <div class="application">
      <wd-navbar>
        <div slot="left" class="middle" @click="$goHome">
          <wd-icon name="arrow-left" />
        </div>
        <div>
          <span>选择查看对象</span>
        </div>
      </wd-navbar>
      <div class="list" v-if="dataList.length > 0">
        <template v-for="(item,index) in dataList">
          <wd-card v-if="item.acctType == '1'" :key="index">
            <div slot="title" class="titleWrap">
              <span class="title">{{item.acctName || '--'}}(水表)<img src="../../assets/img/water.svg" style="width: 20px" /></span><span><wd-tag type="danger" mark v-if="item.equipmentStatus == '关'">关</wd-tag><wd-tag type="success" mark v-if="item.equipmentStatus == '开'">开</wd-tag><wd-tag type="info" mark v-else>--</wd-tag></span>
            </div>
            <div class="items">
              <ul>
                <li>表计编码：{{item.addr || '--'}}</li>
                <li>账户编号：{{item.acctId || '--'}}</li>
                <li>剩余金额：{{item.remainingBalance || '0'}}</li>
                <li>抄表示数：{{item.equipmentLatestLarge || '--'}}</li>
                <li style="width: 100%">抄表时间：{{item.currentDealDate || '--'}}</li>
                <li></li>
              </ul>
            </div>
            <div slot="footer">
<!--              <wd-button type="info" size="small" icon="wd-icon-delete-thin" @click="deleteAcct(item.acctId)">移除</wd-button>-->
              <wd-button size="medium" icon="wd-icon-search" type="info" @click="handleOrder(item)">
                缴费记录
              </wd-button>
            </div>
          </wd-card>
          <wd-card v-else-if="item.acctType == '0'" :key="index">
            <div slot="title" class="titleWrap">
              <span class="title">{{item.acctName || '--'}}(电表)<img src="../../assets/img/ele.svg" style="width: 20px" /></span><span><wd-tag type="danger" mark v-if="item.equipmentStatus == '关'">关</wd-tag><wd-tag type="success" mark v-if="item.equipmentStatus == '开'">开</wd-tag><wd-tag type="info" mark v-else>--</wd-tag></span>
            </div>
            <div class="items">
              <ul>
                <li>表计编码：{{item.addr || '--'}}</li>
                <li>账户编号：{{item.acctId || '--'}}</li>
                <li>剩余金额：{{item.remainingBalance || '0'}}</li>
                <li>抄表示数：{{item.equipmentLatestLarge || '--'}}</li>
                <li style="width: 100%">抄表时间：{{item.currentDealDate || '--'}}</li>
                <li></li>
              </ul>
            </div>
            <div slot="footer">
<!--              <wd-button type="info" size="small" icon="wd-icon-delete-thin" @click="deleteAcct(item.acctId)">移除</wd-button>-->
              <wd-button size="medium" icon="wd-icon-search" type="info" @click="handleOrder(item)">
                 缴费记录
              </wd-button>
            </div>
          </wd-card>
          <wd-card v-else-if="item.acctType == '2' || item.acctType == '3' || item.acctType == '4'" :key="index">
            <div slot="title" class="titleWrap">
              <span class="title">{{item.acctName || '--'}}({{item.acctType == '2' ? '房间费' : item.acctType == '3' ? '物业费' : item.acctType == '4' ? '停车费' : '--'}})<img src="../../assets/img/other.svg" style="width: 20px" /></span><!--<span><wd-tag type="primary" mark>已缴费</wd-tag></span>-->
            </div>
            <div class="items">
              <ul>
                <li>账户编号：{{item.acctId || '--'}}</li>
                <li>支付周期：{{item.payCycle || '--'}}</li>
                <li>到期时间：{{item.expireDate || '--'}}</li>
                <li>剩余金额：{{item.remainingBalance || '0'}}</li>
                <li>业主姓名：{{item.custName || '--'}}</li>
                <li></li>
              </ul>
            </div>
            <div slot="footer">
<!--              <wd-button type="info" size="small" icon="wd-icon-delete-thin" @click="deleteAcct(item.acctId)">移除</wd-button>-->
              <wd-button size="medium" icon="wd-icon-search" type="info" @click="handleOrder(item)">
                缴费记录
              </wd-button>
            </div>
          </wd-card>
          <wd-card v-else-if="item.acctType == '5'" :key="index">
            <div slot="title" class="titleWrap">
              <span class="title">{{item.acctName || '--'}}(组合缴费)<img src="../../assets/img/groupInfo.svg" style="width: 20px" /></span><!--<span><wd-tag type="primary" mark>已缴费</wd-tag></span>-->
            </div>
            <div class="items">
              <ul>
                <li>账户编号：{{item.acctId || '--'}}</li>
                <li>剩余金额：{{item.remainingBalance || '--'}}</li>
                <li>业主姓名：{{item.custName || '--'}}</li>
                <li></li>
              </ul>
            </div>
            <div slot="footer">
<!--              <wd-button type="info" size="small" icon="wd-icon-delete-thin" @click="deleteAcct(item.acctId)">移除</wd-button>-->
              <wd-button size="medium" icon="wd-icon-search" type="info" @click="handleOrder(item)">
                缴费记录
              </wd-button>
            </div>
          </wd-card>
        </template>
      </div>
      <div style="background-color: #fff" v-else>
        <wd-status-tip type="content" tip="暂无内容"/>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        data(){
            return {
                empty: false,
                dataList: [],
                appUserId: null,
                roleId: null
            }
        },
        created() {
            this.appUserId = localStorage.getItem("appUserId")
            this.roleId    = localStorage.getItem("roleKey")
            this.getList()
        },
        methods: {
            getList(){
                this.$http.get("/external/appUserAcct/list", { params: { roleId: this.roleId, appUserId: this.appUserId } }).then(res=>{
                    if( res.code == 200 ) {
                        this.dataList = res.rows
                    }
                })
            },
            getListFresh(){
                this.dataList = []
                this.$http.get("/external/appUserAcct/list", { params: { roleId: this.roleId, appUserId: this.appUserId } }).then(res=>{
                    if( res.code == 200 ) {
                        this.$toast.success("刷新成功！")
                        this.dataList = res.rows
                    }
                })
            },
            deleteAcct(acctId){
                this.$messageBox.confirm('是否移除缴费账户', '提示').then(() => {
                    this.$http.put("/external/appUserAcct/delete", {acctId: acctId, roleId: this.roleId, appUserId: this.appUserId}).then(res=>{
                        if( res.code == 200 ) {
                            this.$toast.success("移除成功！")
                            setTimeout(()=>{
                                this.getList()
                            }, 300)
                        }
                    })
                })
            },
            handleOrder(row){
                this.$router.push({ name: "payLog", params: row })
            }
        }
    }
</script>

<style lang="less" scoped>
  .topTips{
    background-color: #fff;
    padding: 10px 0;
    font-size: 12px;
    .tips{
      width: calc(100% - 40px);
      margin: 0 20px;
      color:#f0883a;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .application{
    width: 100vw;
    background-color: #f6f6f6;
    min-height: 100vh;
  }
  .list{
    padding: 15px 0;
    .button{
      /deep/ .wd-button__txt{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .items{
      ul{
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        li{
          font-size: 12px;
          white-space: nowrap;
          width: 50%;
          list-style: none;
          &:not(:nth-child(-n+2)) {
            margin-top: 15px;
          }
        }
      }
    }
    .titleWrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title{
        font-size: 14px;
        display: flex;
        align-items: center;
        img{
          margin-left: 10px;
        }
      }
    }
    .footer{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .addBtn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 20px;
    .button{
      /deep/ .wd-button__txt{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
